import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { IPage, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    container,
    title,
    subtitle,
    text,
    list,
    chip,
    label,
} from './section-ecommerce-functionalities.module.scss';
import MobileShopIcon from '../../assets/images/svg/mobile-shop.svg';
import MegaphoneIcon from '../../assets/images/svg/megaphone.svg';
import SuitcaseIcon from '../../assets/images/svg/suitcase.svg';
import PaperPenIcon from '../../assets/images/svg/paper-pen.svg';
import BracketsIcon from '../../assets/images/svg/brackets.svg';
import PenWebIcon from '../../assets/images/svg/pen-web.svg';
import ShieldIcon from '../../assets/images/svg/shield.svg';
import UserIcon from '../../assets/images/svg/user.svg';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { useT } from '../../hooks/use-translation';
import { getNodes } from '../../utils/get-nodes';

import Section from '../hoc/section';
import Markdown from '../hoc/markdown';

interface ISectionEcommerceFunctionalitiesProps {
    className?: string;
    id?: string;
}

interface ISectionEcommerceFunctionalitiesQueryResult {
    image: ImageDataLike;
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname'>>;
}

const SectionEcommerceFunctionalities: React.FC<ISectionEcommerceFunctionalitiesProps> = ({
    className = '',
    id,
}) => {
    const { locale } = usePageContext();
    const { image, allPage } = useStaticQuery<ISectionEcommerceFunctionalitiesQueryResult>(query);
    const { t } = useT();

    const pathname = getNodes(allPage).find((page) => page.locale === locale)?.pathname || '';

    const functionalities = [
        {
            icon: MobileShopIcon,
            label: t('section.functionalities.item.1'),
        },
        {
            icon: MegaphoneIcon,
            label: t('section.functionalities.item.2'),
        },
        {
            icon: SuitcaseIcon,
            label: t('section.functionalities.item.3'),
        },
        {
            icon: PaperPenIcon,
            label: t('section.functionalities.item.4'),
        },
        {
            icon: BracketsIcon,
            label: t('section.functionalities.item.5'),
        },
        {
            icon: PenWebIcon,
            label: t('section.functionalities.item.6'),
        },
        {
            icon: ShieldIcon,
            label: t('section.functionalities.item.7'),
        },
        {
            icon: UserIcon,
            label: t('section.functionalities.item.8'),
        },
    ];

    return (
        <Section
            id={id}
            className={className}
            theme="light"
            height="auto"
            circleDisplay="none"
            contentDisplay="right"
            image={image && getImage(image)}
            titleProps={{
                children: t('section.functionalities.title'),
                subtitle: t('section.functionalities.subtitle'),
                className: title,
                subtitleClass: subtitle,
                subtitleColor: 'pink',
                weight: 'bold',
                size: 'large',
            }}
            buttonProps={{
                children: t('section.functionalities.button'),
                color: 'yellow',
                kind: 'normalDark',
                to: pathname,
                as: 'link',
            }}
        >
            <div className={container}>
                <Markdown className={text}>{t('section.functionalities.text')}</Markdown>
                <ul className={list}>
                    {functionalities.map((functionality, index) => {
                        const Icon = functionality.icon;
                        return (
                            <li key={`functionality-${index}`} className={chip}>
                                <Icon />
                                <p className={label}>{functionality.label}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        image: file(relativePath: { eq: "ecommerce-functionalities.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        allPage(filter: { type: { eq: "functionalities-alterpress" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;

export default SectionEcommerceFunctionalities;
